.App {
  text-align: left;
  word-wrap: break-word;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  word-wrap: break-word;
}

.App-link {
  color: #61dafb;
}

.rcdiv {
  border-radius: 3px;
  border: 1px solid #999999;
  margin: 5px;
  padding: 10px;
}

.outdiv {
  /* border-radius: 3px;
  border: 1px solid #999999; */
  margin-left: 20px;
  padding: 10px;
}

.greydiv {
  background-color: lightgray;
  margin-left: 20px;
  padding: 10px;
}

.error {
  color: red;
}

.valid {
  color: green;
}

.red-alert {
  color: red;
  background-color: lightpink;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

table {
  border: 1px solid black;
}

td {
  border: 1px solid lightgray;
}

.bluetable {
  font-family: "Spoqa Han Sans Neo", sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.bluetable td,
.bluetable th {
  border: 1px solid #ddd;
  padding: 8px;
}

.bluetable tr:nth-child(even) {
  background-color: #f2f2f2;
}

.bluetable tr:hover {
  background-color: #ddd;
}

.bluetable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #b3bae0;
  color: black;
}
